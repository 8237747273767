import React, {SyntheticEvent} from 'react';
import UIScreen from "../UIScreen";
import {GameStepScreenProps} from "../steps/type";
import {SimpleButton} from "../Button";
import useMutexCallback from "../useMutexCallback";


const configurations = [
    {
        foot: 4,
        hands: 2,
        elbow: 0,
        knee: 0,
        secret: 'Wald',
    },
    {
        foot: 4,
        hands: 4,
        elbow: 4,
        knee: 0,
        secret: 'Berg',
    },
    {
        foot: 3,
        hands: 2,
        elbow: 2,
        knee: 0,
        secret: 'Fluss',
    },
    {
        foot: 4,
        hands: 4,
        elbow: 4,
        knee: 4,
        secret: 'Sonne',
    },
    {
        foot: 3,
        hands: 4,
        elbow: 3,
        knee: 0,
        secret: 'Palme',
    },
    {
        foot: 7,
        hands: 4,
        elbow: 3,
        knee: 0,
        secret: 'Schiff',
    },
] as const;

function Bridge({state, update}: GameStepScreenProps) {
    const [code, setCode] = React.useState('');

    const configuration = configurations[(state.teamIndex + 2) % configurations.length];

    const [onSubmit, submitting] = useMutexCallback(async (e: SyntheticEvent) => {
        e.preventDefault();

        if (code.toLowerCase() !== configuration.secret.toLowerCase()) {
            alert('Das Wort ist so leider nicht richtig!. Gucke nochmal ob alles richtig geschrieben ist');
            return
        }

        update({});
    }, [update, code]);

    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-4xl font-bold font-headline">Atomspiel an der Brücke</h1>

                <div className="max-w-4xl w-full flex flex-col space-y-4">
                    <p>
                        Ihr steht vor einer wackeligen Brücke.
                        Diese kann nur mit bestimmten Körperteilen betreten werden, sonst bricht sie ein.
                        Überquert die Brücke mit folgenden Körperteilen gemeinsam und sucht weiter nach
                        dem Schatz der Insel Arnie:
                    </p>

                    <ul className="text-2xl text-center">
                        {configuration.foot > 0 && (
                            <li>
                                {configuration.foot} Füße
                            </li>
                        )}
                        {configuration.hands > 0 && (
                            <li>
                                {configuration.hands} Hände
                            </li>
                        )}
                        {configuration.elbow > 0 && (
                            <li>
                                {configuration.elbow} Ellbogen
                            </li>
                        )}
                        {configuration.knee > 0 && (
                            <li>
                                {configuration.knee} Knie
                            </li>
                        )}
                    </ul>

                    Sobald ihr es geschafft habt, gibt Euch Eure Lehrkraft das Geheimewort.

                    <form className="flex flex-col space-y-2" onSubmit={onSubmit} aria-disabled={submitting}>
                        <input type="text" className="form-input text-black text-center" placeholder="Das Geheimwort" value={code} onChange={e => setCode(e.target.value)} />
                        <SimpleButton onClick={onSubmit} disabled={submitting}>
                            {submitting ? '...' : 'Weiter geht\'s!'}
                        </SimpleButton>
                    </form>
                </div>
            </div>
        </UIScreen>
    );
}

export default Bridge;
