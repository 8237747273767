import * as React from "react";
import {Random} from "./random";
import {useMemo} from "react";

const bodies = {
    blue: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/blue_gopher.png',
    pink: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/pink_gopher.png',
    green: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/pink_gopher.png',
    brown: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/brown_gopher.png',
    purple: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/purple_gopher.png',
    blueSpike: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/010-Body/blue_spike_hair.png',
};

type BodyType = keyof typeof bodies;

const getBodyTypeFromNextNumber = (rngNN: number) => {
    const bodyTypes = Object.keys(bodies) as BodyType[];
    return bodyTypes[Math.floor(rngNN * bodyTypes.length)];
};

const eyes = {
    crazy: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/020-Eyes/crazy_eyes.png',
    eyelashes: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/020-Eyes/eyelashes.png',
    eyes: 'https://storage.googleapis.com/gopherizeme.appspot.com/artwork/020-Eyes/eyes.png',
};

type EyeType = keyof typeof eyes;

const getEyeTypeFromNextNumber = (rngNN: number) => {
    const eyeTypes = Object.keys(eyes) as EyeType[];
    return eyeTypes[Math.floor(rngNN * eyeTypes.length)];
};

type Configuration = {
    body: BodyType;
    eyes: EyeType;
}

export function getRandomConfiguration(seed: number): Configuration {
    const rng = new Random(seed);
    return {
        body: getBodyTypeFromNextNumber(rng.nextNumber()),
        eyes: getEyeTypeFromNextNumber(rng.nextNumber()),
    };
}

export function Gopher(props: { configuration: Configuration }) {
    return (
        <div className="w-8 h-8 relative">
            <img className="absolute inset-0" src={bodies[props.configuration.body]} alt="körper"/>
            <img className="absolute inset-0" src={eyes[props.configuration.eyes]} alt="augen"/>
        </div>
    );
}

export function GopherSwarm(props: { amount: number, seed: number }) {

    const configurations = useMemo(() => {
        const rng = new Random(props.seed);

        return Array(props.amount).fill(0).map(() => ({
            body: getBodyTypeFromNextNumber(rng.nextNumber()),
            eyes: getEyeTypeFromNextNumber(rng.nextNumber()),
        }));
    }, [props]);


    return (
        <div className="relative">
            {configurations.map((conf, i) => (
                <div key={i} className="absolute" style={{
                    transform: `translate(${(Math.random() - 0.5) * 2 * 30}px, ${(Math.random() - 0.5) * 2 * 30}px)`,
                }}>
                    <Gopher configuration={conf} />
                </div>
            ))}
        </div>
    )

}