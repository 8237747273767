import {DependencyList, useCallback, useEffect, useRef, useState} from "react";

export default function useMutexCallback<T extends (...args: any[]) => Promise<any>>(callback: T, deps: DependencyList): [T, boolean] {

    const isMounted = useRef<boolean>();

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        }
    }, [isMounted]);

    const [s, setS] = useState(false);

    const fn = useCallback(async (...args) => {
        try {
            setS(true);
            return await callback(...args);
        } finally {
            if (isMounted.current) {
                setS(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, setS, isMounted, ...deps]);

    // @ts-ignore
    return [s ? undefined : fn, s];
}