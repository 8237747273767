import React, {useMemo, useState} from 'react';
import UIScreen from "../UIScreen";
import {GameStepScreenProps} from "../steps/type";
import {SimpleButton} from "../Button";
import useMutexCallback from "../useMutexCallback";
import {Random, shuffle} from "../random";
import cx from "classnames";
import {useDebouncedCallback} from "../hooks";


const dim = 15;
const allWords = [
    'Zelt',
    'Brotdose',
    'Kompass',
    'Schuhe',
    'Taschenlampe',
    'Flasche',
    'Rucksack',
    'Schlafsack',
    'Sonnencreme',
    'Hut',
].map(w => w.toUpperCase());

function Crossword({state, update}: GameStepScreenProps) {

    const [onNext, submitting] = useMutexCallback(async () => {
        update({});
    }, [update]);


    const [grid, positions] = useMemo(() => {
        const rnd = new Random(state.teamIndex);
        let grid = emptyGrid;

        const words = shuffle(allWords, state.teamIndex);

        const positions: Record<string, GridEncoding> = {};

        loop:
            for (let word of words) {
                const paddedWord = `.${word}.`; // pad with dots do prevent "touching" words...

                let tries = 0;
                while (tries < 25) {
                    tries++;
                    const dir = words.indexOf(word) % 2 as 0 | 1;

                    const maxX = dir === 1 ? dim - paddedWord.length : dim;
                    const maxY = dir === 0 ? dim - paddedWord.length : dim;

                    const paddedEncoding = {
                        x: Math.round(rnd.nextNumber() * maxX),
                        y: Math.round(rnd.nextNumber() * maxY),
                        dir,
                        length: paddedWord.length,
                    };
                    const [nextGrid, hasCollision] = applyWord(grid, paddedWord, paddedEncoding);

                    const encoding = {
                        x: dir === 0 ? paddedEncoding.x : paddedEncoding.x + 1,
                        y: dir === 1 ? paddedEncoding.y : paddedEncoding.y + 1,
                        dir,
                        length: word.length,
                    };

                    if (!hasCollision) {
                        grid = nextGrid;
                        positions[word] = encoding;
                        continue loop;
                    }
                }
            }

        grid = fillGrid(grid, state.teamIndex);

        return [grid, positions];
    }, [state]);



    const [foundWordEncoding, setFoundWordEncoding] = useState<Array<GridEncoding>>([]);
    const highlightGreenSelection = useMemo(() => {

        const sel = emptySelection();

        for (let gridEncoding of foundWordEncoding) {
            let { x, y, dir, length } = gridEncoding;

            for (let i = 0; i < length; i++) {
                sel[x][y] = true;
                if (dir === 1) {
                    x++;
                } else {
                    y++;
                }
            }
        }
        return sel;
    }, [foundWordEncoding]);


    const [currentSelection, setCurrentSelection] = useState<Selection>(emptySelection());

    const [isMouseDown, setMouseDown] = useState(false);
    const onUp = useDebouncedCallback(() => {
        setMouseDown(false);

        const encoding = selectionToGridEncoding(currentSelection);

        if (encoding === null) {
            return;
        }

        const matches = Object.values(positions).filter(({ x, y, dir, length }) => x === encoding?.x && y === encoding?.y && dir === encoding?.dir && length === encoding?.length).length > 0;

        if (matches) {
            setFoundWordEncoding([...foundWordEncoding, encoding]);
        }

        setCurrentSelection(emptySelection());
    }, 100);

    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-4xl font-bold font-headline">Versteckte Wörter</h1>

                <div className="max-w-4xl w-full flex flex-col space-y-4">
                    <p>
                        Die Inselbewohner haben Begriffe versteckt. Findet die versteckten Wörter, um auf dem richtigen
                        Weg zu bleiben.
                    </p>


                    <div className="bg-white text-black p-2 mx-auto" onMouseDown={() => setMouseDown(true)} onMouseUp={onUp} onMouseLeave={onUp}>
                        {grid.map((row, x) => (
                            <div key={x}>
                                {row.map((letter, y) => (
                                    <button
                                        key={y}
                                        className={cx("h-8 w-8 border border-gray-400", {
                                            "bg-orange-300": currentSelection[x][y],
                                            "bg-green-200": highlightGreenSelection[x][y],
                                        })}
                                        onMouseDown={() => {
                                            setCurrentSelection(setSelection(currentSelection, x, y, true));
                                            return false;
                                        }}
                                        onMouseOver={() => {
                                            if (isMouseDown) {
                                                setCurrentSelection(setSelection(currentSelection, x, y, true))
                                            }
                                        }}
                                    >
                                        {letter}
                                    </button>
                                ))}
                            </div>
                        ))}
                    </div>


                    {foundWordEncoding.length < Object.keys(positions).length && (
                        <span className="text-center">
                            {foundWordEncoding.length} / {Object.keys(positions).length}
                        </span>
                    )}

                    {foundWordEncoding.length >= Object.keys(positions).length && (
                        <SimpleButton onClick={onNext} disabled={submitting}>
                            Weiter geht's
                        </SimpleButton>
                    )}


                </div>
            </div>
        </UIScreen>
    );
}

export default Crossword;

type Grid = Array<Array<string>>;
type GridEncoding = {
    x: number;
    y: number;
    dir: 0 | 1;
    length: number;
}
const emptyGrid: Grid = Array(dim).fill(0).map(() => ([
    ...(Array(dim).fill(0).map(() => '_')),
]));

function applyWord(grid: Grid, word: string, xyd: GridEncoding): [Grid, boolean] {
    const copy = Array.from(grid).map(row => Array.from(row));

    let {x, y, dir} = xyd;

    let hasCollision = false;
    for (let i = 0; i < word.length; i++) {
        if (copy[x] === undefined || copy[x][y] === undefined) {
            return [copy, true];
        }

        const collision = copy[x][y] !== "" && copy[x][y] !== "_";
        hasCollision = hasCollision || collision;

        copy[x][y] = word[i];

        if (collision) {
            copy[x][y] = '?';
        }

        if (dir === 1) {
            x++;
        } else {
            y++;
        }
    }

    return [copy, hasCollision];
}

function fillGrid(grid: Grid, seed: number): Grid {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    const rng = new Random(seed);
    const copy = Array.from(grid).map(row => Array.from(row));
    for (let i = 0; i < grid.length; i++) {
        for (let j = 0; j < grid[i].length; j++) {
            if (copy[i][j] === '' || copy[i][j] === '.' || copy[i][j] === '_') {
                copy[i][j] = characters[Math.floor(rng.nextNumber() * characters.length)];
            }
        }
    }

    return copy;
}

type Selection = Array<Array<boolean>>;
const emptySelection = () => Array(dim).fill(0).map(() => ([
    ...(Array(dim).fill(0).map(() => false)),
]));
function setSelection(current: Selection, x: number, y: number, v: boolean): Selection {
    const copy = Array.from(current).map(row => Array.from(row));

    copy[x][y] = v;

    return copy;
}

function selectionToGridEncoding(selection: Selection): GridEncoding | null {

    let x, y;

    outer:
    for (let i = 0; i < selection.length; i++) {
        for (let j = 0; j < selection[i].length; j++) {
            if (selection[i][j]) {
                x = i;
                y = j;
                break outer;
            }
        }
    }

    if (x === undefined || y === undefined) {
        return null;
    }

    let dir: 0 | 1;

    // find direction
    if (x + 1 < selection.length) {
        if (y + 1 < selection[x].length) {
            dir = selection[x][y + 1] ? 0 : 1;
        } else {
            dir = 1;
        }
    } else {
        dir = 0;
    }

    const length = selection.map(row => row.filter(s => s).length).reduce((a, b) => a + b, 0);



    return { x, y, dir, length };
}