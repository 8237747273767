import React, {useEffect, useState} from 'react';
import UIScreen from "../UIScreen";
import {GameStepScreenProps} from "../steps/type";
import useMutexCallback from "../useMutexCallback";

import lock from '../img/lock.png';
import {SimpleButton} from "../Button";

type wordPair = [string, number];

const wordsPerTeamIndex: Record<number, [wordPair, wordPair, wordPair, wordPair]> = {
    0: [
        ['Schatzkarte', 3],
        ['Goldschmuck', 2],
        ['Palme', 2],
        ['Kokosnüsse', 4],
    ],
    1: [
        ['Schatzkiste', 3],
        ['Taschenlampe', 4],
        ['Abenteuerreise', 6],
        ['Wasserfall', 3],
    ],
    2: [
        ['Strand', 1],
        ['Brücke', 2],
        ['Taschenlampe', 4],
        ['Wald', 1],
    ],
    3: [
        ['Abenteuerreise', 6],
        ['Palme', 2],
        ['Brücke', 2],
        ['Wasserfall', 3],
    ],
    4: [
        ['Wald', 1],
        ['Strand', 1],
        ['Schatzkiste', 3],
        ['Wasserfall', 3],
    ],
    5: [
        ['Wald', 1],
        ['Strand', 1],
        ['Brücke', 2],
        ['Schatzkarte', 3],
    ],
};

function Lock({state, update}: GameStepScreenProps) {

    const words = wordsPerTeamIndex[state.teamIndex];

    const [onNext, submitting] = useMutexCallback(async () => {
        update({});
    }, [update]);


    const [code, setCode] = useState([0, 0, 0, 0]);
    const [correct, setCorrect] = useState(false);

    useEffect(() => {
        let tid: NodeJS.Timeout;
        const c = words.map((pair, i) => code[i] === pair[1]).reduce((a, b) => a && b, true);
        if (c) {
            tid = setTimeout(() => {
                setCorrect(c);
            }, 300);
        }

        return () => {
            if (tid !== undefined) {
                clearTimeout(tid);
            }
        };
    }, [words, code]);

    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-4xl font-bold font-headline">Das Schloss</h1>

                <div className="max-w-4xl w-full flex flex-col space-y-4">
                    <p>
                        Die Wörter über der Zahl verraten Euch durch die Anzahl der Silben die Kombination.
                    </p>

                    <div className="relative" style={{ height: '48rem'}}>
                        <div className="absolute inset-0">
                            <img src={lock} alt="Schloss" className="w-full h-full object-cover"/>
                        </div>
                        <div className="absolute bottom-0 right-0 left-0 h-2/3 mb-20 z-10 flex flex-row space-x-2 justify-center">
                            {words.map(([word], i) => (
                                <div key={word} className="flex flex-col items-center text-black bg-white bg-opacity-50 px-4 py-4 rounded-lg shadow-inner transition duration-150" style={{ opacity: correct ? 0 : 1}}>
                                    <span className="text-xl">{word}</span>
                                    <input type="number" className="w-16 h-16 text-black text-2xl font-bold text-center rounded-md shadow-inner" value={code[i]} onChange={e => {

                                        const newCode = Array.from(code);
                                        const v = parseInt(e.target.value, 10) % 10;
                                        newCode[i] = v < 0 ? 10 + v : v;

                                        setCode(newCode);


                                    }}/>
                                </div>
                            ))}
                        </div>
                    </div>
                    {correct && (
                        <SimpleButton onClick={onNext} disabled={submitting} className="relative z-20">
                            Euer Schloss ist geöffnet. Hier geht's weiter
                        </SimpleButton>
                    )}
                </div>
            </div>
        </UIScreen>
    );
}

export default Lock;
