import React, {SyntheticEvent} from 'react';
import useMutexCallback from "../useMutexCallback";
import db from "../db";
import UIScreen from "../UIScreen";
import {SimpleButton} from "../Button";
import {GameStepScreenProps} from "./type";


function TeamSelection({ update }: GameStepScreenProps) {

    const [code, setCode] = React.useState('');

    const [onStart, starting] = useMutexCallback(async (e: SyntheticEvent) => {
        e.preventDefault();

        const team = await db.collection("teams").doc(code.trim().toUpperCase()).get();

        if (!team.exists) {
            alert('Team-Code nicht gültig!');
            return;
        }

        const data = team.data()!;


        update({
            teamCode: code,
            ...data,
            step: data.step || 'SETUP_MEMBERS',
        });
    }, [update, code]);

    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-4xl font-bold font-headline">Insel Arnie</h1>
                <form className="flex flex-col space-y-2" onSubmit={onStart} aria-disabled={starting}>
                    <input type="text" className="form-input text-black text-center" placeholder="Dein Team-Code" value={code} onChange={e => setCode(e.target.value)} />
                    <SimpleButton onClick={onStart} disabled={starting}>
                        {starting ? '...' : 'Los legen!'}
                    </SimpleButton>
                </form>
            </div>
        </UIScreen>
    );
}

export default TeamSelection;
