import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import "typeface-inter";
import "typeface-amatic-sc";
import "./tailwind.generated.css";

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);

// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        ReactDOM.render(
            <React.StrictMode>
                <NextApp/>
            </React.StrictMode>,
            root
        )
    })
}