import {GameState} from "../state";
import Bridge from "../puzzels/Bridge";
import Crossword from "../puzzels/Crossword";
import Lock from "../puzzels/Lock";


export type GameStepScreenProps = {
    state: GameState;

    update: (newState: Partial<GameState>) => void;
}

type PuzzleType = 'BRIDGE' | 'CROSSWORD' | 'LOCK';

export const puzzleOrder: Record<number, [PuzzleType, PuzzleType, PuzzleType]> = {
    0: ['BRIDGE', 'CROSSWORD', 'LOCK'],
    1: ['CROSSWORD', 'BRIDGE', 'LOCK'],
    2: ['LOCK', 'CROSSWORD', 'BRIDGE'],
    3: ['BRIDGE', 'LOCK', 'CROSSWORD'],
    4: ['CROSSWORD', 'LOCK', 'BRIDGE'],
    5: ['LOCK', 'BRIDGE', 'CROSSWORD'],
};

export const puzzleComponentMapping = {
    'BRIDGE': Bridge,
    'CROSSWORD': Crossword,
    'LOCK': Lock,
};