import React, {useState} from 'react';
import UIScreen from "../UIScreen";
import {OutlineButton, SimpleButton} from "../Button";
import _ from 'lodash';
import useMutexCallback from "../useMutexCallback";
import {GameStepScreenProps} from "./type";


function SetupMembers({ state, update }: GameStepScreenProps) {

    const { teamCode } = state;

    const [members, setMembers] = useState<Record<number, string>>({
        0: '',
    });

    const [onContinue, saving] = useMutexCallback(async () => {


        const names = Object.values(members).filter(n => !!n);

        if (names.length < 2) {
            alert('Ihr müsst mindestens 2 in einem Team sein.');
            return;
        }

        update({
            step: 'TASK_0',
            names,
        });

    }, [update, teamCode, members]);

    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-2xl font-bold">Erstmal müssen wir wissen, mit wem wir es hier zu tun
                    haben</h1>

                <div className="max-w-4xl w-full flex flex-col space-y-4">
                    <p>Der oder die erste mutige Erkunder/in kann jetzt seinen Namen in das Fahrtenbuch schreiben!</p>


                    <ul className="flex flex-col space-y-2">
                        {Object.entries(members)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .map(([i, name]) => (
                                <li key={i} className="inline-flex flex-row items-center">
                                    <span className="font-medium w-1/5">Erkunder/in #{parseInt(i, 10) + 1}</span>
                                    <input type="text" className="form-input text-black" value={name} onChange={e => setMembers({
                                        ...members,
                                        [i]: e.target.value,
                                    })}/>
                                    {i !== "0" && (
                                        <SimpleButton className="ml-2" onClick={() => setMembers(_.omit(members, i))}>
                                            X
                                        </SimpleButton>
                                    )}
                                </li>
                            ))}
                            <li>
                                <OutlineButton onClick={() => {
                                    setMembers({
                                        ...members,
                                        [parseInt(Object.keys(members).sort((a, b) => b.localeCompare(a))[0], 10) + 1]: '',
                                    })
                                }}>
                                    Nächste Person eintragen!
                                </OutlineButton>
                            </li>
                    </ul>
                </div>


                <SimpleButton onClick={onContinue} disabled={saving}>
                    {saving ? '...' : 'Seid ihr fertig? Dann geht\'s hier weiter!'}
                </SimpleButton>
            </div>
        </UIScreen>
    );
}

export default SetupMembers;
