import React from 'react';
import {GameStepScreenProps, puzzleComponentMapping, puzzleOrder} from "./type";

function Task0({state, update}: GameStepScreenProps) {
    const puzzle = puzzleOrder[state.teamIndex][0];

    const Comp = puzzleComponentMapping[puzzle];

    return <Comp state={state} update={(state) => update({
        ...state,
        step: 'TASK_1',
    })} />;
}

export default Task0;
