import React from 'react';
import Client from "./Client";
import Overview from "./Overview";
import StartNewSession from "./StartNewSession";


function App() {


    // eslint-disable-next-line no-restricted-globals
    if (location.href.includes('/uebersicht')) {
        return <Overview />;
    }

    // eslint-disable-next-line no-restricted-globals
    if (location.href.includes('/neues-spiel')) {
        return <StartNewSession />;
    }

    return <Client />;
}

export default App;
