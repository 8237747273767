import React from 'react';
import {GameStepScreenProps, puzzleComponentMapping, puzzleOrder} from "./type";

function Task1({state, update}: GameStepScreenProps) {
    const puzzle = puzzleOrder[state.teamIndex][1];

    const Comp = puzzleComponentMapping[puzzle];

    return <Comp state={state} update={(state) => update({
        ...state,
        step: 'TASK_2',
    })} />;
}

export default Task1;
