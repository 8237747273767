import React, {useLayoutEffect, useRef, useState} from 'react';

import map from "./map_sepia.png";
import db from "./db";
import UIScreen from "./UIScreen";
import {SimpleButton} from "./Button";
import useMutexCallback from "./useMutexCallback";
import {useCollection} from "react-firebase-hooks/firestore";
import {GameState, GameStep} from "./state";
import {GopherSwarm} from './Gopher';

function Overview() {

    const [sessionId, setSessionId] = useState('');

    return (
        <div className="min-h-screen w-full relative">
            <div className="absolute inset-0 py-8">
                <img src={map} alt="map" className="w-full h-full object-contain"/>

                <div className="absolute inset-0 z-10">
                    {!sessionId && (
                        <AskForSessionId onSelect={setSessionId}/>
                    )}
                    {!!sessionId && <MapDisplay sessionId={sessionId}/>}
                </div>
            </div>
            {!!sessionId && (
                <div className="absolute top-0 right-0 z-20 inline-flex">
                    <div className="bg-white text-black px-4 py-1">
                        {sessionId}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Overview;


const AskForSessionId: React.FunctionComponent<{ onSelect: (sessionId: string) => void; }> = ({onSelect}) => {
    const [sessionId, setSessionId] = useState('');

    const [onContinue, working] = useMutexCallback(async () => {

        const res = await db.collection('sessions').doc(sessionId).get();

        if (res.exists) {
            return onSelect(sessionId);
        } else {
            alert('Session ID nicht gültig!');
        }
    }, [sessionId, onSelect]);

    return (
        <div className="absolute inset-0 z-10">
            <UIScreen>
                <div className="flex items-center justify-center space-x-2">
                    <input type="text" className="form-input text-black" placeholder="ABC-123-456" value={sessionId}
                           onChange={e => setSessionId(e.target.value.trim())}/>
                    <SimpleButton onClick={onContinue} disabled={working}>
                        {working ? '...' : 'Auf geht\'s'}
                    </SimpleButton>
                </div>
            </UIScreen>
        </div>
    );
};


const positionMapping: Record<GameStep, { top: number, left: number }[]> = {
    TEAM_SELECTION: [{top: 15, left: 55}, {top: 59, left: 86}, {top: 73, left: 76}, {top: 83, left: 53}, {top: 71, left: 23}, {top: 39, left: 29}],
    SETUP_MEMBERS: [{top: 15, left: 55}, {top: 59, left: 86}, {top: 73, left: 76}, {top: 83, left: 53}, {top: 71, left: 23}, {top: 39, left: 29}],
    TASK_0: [{top: 25, left: 57}, {top: 49, left: 82}, {top: 68, left: 72}, {top: 74, left: 64}, {top: 61, left: 21}, {top: 47, left: 36}],
    TASK_1: [{top: 31, left: 59}, {top: 46, left: 77}, {top: 64, left: 67}, {top: 70, left: 60}, {top: 60, left: 30}, {top: 51, left: 40}],
    TASK_2: [{top: 41, left: 57}, {top: 46, left: 66}, {top: 58, left: 62}, {top: 61, left: 56}, {top: 60, left: 42}, {top: 51, left: 46}],
    FINISH: [{top: 49, left: 54}, {top: 50, left: 56}, {top: 53, left: 57}, {top: 55, left: 55}, {top: 55, left: 52}, {top: 52, left: 51}],
};

const MapDisplay: React.FunctionComponent<{ sessionId: string }> = ({sessionId}) => {

    const [teamDocs] = useCollection(db.collection('teams').where('sessionId', '==', sessionId));

    const teams = teamDocs?.docs.map(d => d.data() as GameState);

    return (
        <MapSizer>
            {teams?.map((team, i) => (
                <Positioned key={team.teamCode || i} {...positionMapping[team.step || 'TEAM_SELECTION'][team.teamIndex]}>
                    <GopherSwarm amount={team.names?.length ?? 1} seed={i} />
                    <span className="bg-orange-200 w-6 h-6 block rounded-full text-orange-800 text-center align-middle">
                        {team.teamIndex + 1}
                    </span>
                </Positioned>
            ))}
        </MapSizer>
    );
};

const Positioned: React.FunctionComponent<{ top: number, left: number }> = ({top, left, children}) => (
    <div
        className="absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-150 ease-in-out"
        style={{top: `${top}%`, left: `${left}%`}}
    >
        {children}
    </div>
);

const MapSizer: React.FunctionComponent = (props) => {
    const [dim, setDim] = useState(0);

    const ref = useRef<HTMLDivElement>(null);

    const updateSize = () => {
        const el = ref.current;
        if (!el) {
            return;
        }
        setDim(Math.min(el.clientWidth, el.clientHeight));
    };

    useLayoutEffect(() => {
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, [setDim, ref]);

    return (
        <div ref={ref} className="w-full h-full flex">
            <div style={{width: dim, height: dim}} className="m-auto relative">
                {props.children}
            </div>
        </div>
    );
};