import React, {PropsWithChildren} from 'react';

function UIScreen({ children }: PropsWithChildren<{}>) {
    return (
        <div className="h-full w-full flex items-center justify-center py-8 overflow-hidden">
            <div className="max-h-full bg-black bg-opacity-75 w-8/12 overflow-y-auto shadow-lg py-5 px-2 text-white rounded">
                {children}
            </div>
        </div>
    );
}

export default UIScreen;
