import React, {useCallback, useState} from 'react';

import map from "./map_sepia.png";
import TeamSelection from "./steps/00TeamSelection";
import SetupMembers from "./steps/01SetupMembers";
import {GameState} from "./state";
import Task0 from "./steps/02Task0";
import db from "./db";
import Task1 from "./steps/03Task1";
import Task2 from "./steps/03Task2";
import Finish from "./steps/04Finish";


const initialState: GameState = {
    step: "TEAM_SELECTION",

    sessionId: '',
    teamCode: '',
    teamIndex: -1,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const testState: GameState = {teamCode: "DEG-4567", sessionId: "DEG-567-123", names: ["Christian", "Sarah", "Leo"], step: "FINISH", teamIndex: 0};

const componentMapping = {
    'TEAM_SELECTION': TeamSelection,
    'SETUP_MEMBERS': SetupMembers,
    'TASK_0': Task0,
    'TASK_1': Task1,
    'TASK_2': Task2,
    'FINISH': Finish,
} as const;

function Client() {
    const [state, setState] = useState(initialState);
    const update = useCallback((next: Partial<GameState>) => {
        const nextState = {
            ...state,
            ...next,
        };

        setState(nextState);

        if (!!nextState.teamCode) {
            db.collection('teams')
                .doc(nextState.teamCode)
                .update(nextState)
                .catch(console.error.bind(console));
        }
    }, [state, setState]);

    const Component = componentMapping[state.step];

    return (
        <div className="min-h-screen w-full relative">
            <div className="absolute inset-0">
                <img src={map} alt="map" className="w-full h-full object-cover"/>
            </div>

            {state.teamIndex >= 0 && (
                <div className="absolute top-0 right-0 z-20 inline-flex">
                    <div className="bg-white text-black px-4 py-1">
                        Team Nummer {state.teamIndex + 1}
                    </div>
                </div>
            )}

            <div className="absolute inset-0 z-10">
                <Component  state={state} update={update} />
            </div>
        </div>
    );
}

export default Client;
