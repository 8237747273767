import React from 'react';
import UIScreen from "../UIScreen";
import {GameStepScreenProps} from "./type";


function Finish({state, update}: GameStepScreenProps) {
    return (
        <UIScreen>
            <div className="flex flex-col items-center space-y-2">
                <h1 className="text-white text-2xl font-bold">Ziel</h1>

                <div className="max-w-4xl w-full flex flex-col space-y-4">
                    <p className="text-center">
                        Nun seid ihr endlich am Ziel angekommen.
                    </p>
                </div>
            </div>
        </UIScreen>
    );
}

export default Finish;
