import firebase from "firebase/app";
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBWyoW0p6m-DXZTw7p3MnKfrSWset7ZOzs",
    authDomain: "sarahs-world.firebaseapp.com",
    databaseURL: "https://sarahs-world.firebaseio.com",
    projectId: "sarahs-world",
    storageBucket: "sarahs-world.appspot.com",
    messagingSenderId: "106780571000",
    appId: "1:106780571000:web:1eb61619381e4d38d4448d"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default db;