import React, {ButtonHTMLAttributes} from "react";


export const SimpleButton: React.FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement>> = ({children, className, ...props}) => (
    <button
        className={`px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-green focus:border-green-700 active:bg-green-700 transition-default ${className}`.trimRight()}
        {...props}
    >
        {children}
    </button>
);

export const OutlineButton: React.FunctionComponent<ButtonHTMLAttributes<HTMLButtonElement>> = ({children, className, ...props}) => (
    <button
        className={`px-4 py-2 border border-green-500 text-sm leading-5 font-medium rounded-md text-white hover:bg-green-500 focus:outline-none focus:shadow-outline-green focus:border-green-700 active:bg-green-700 transition-default ${className}`.trimRight()}
        {...props}
    >
        {children}
    </button>
);