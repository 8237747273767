import React, {useState} from 'react';

import map from "./map_sepia.png";
import db from "./db";
import UIScreen from "./UIScreen";
import {SimpleButton} from "./Button";
import useMutexCallback from "./useMutexCallback";

function StartNewSession() {

    const [sessionId, setSessionId] = useState('');
    const [teamCodes, setTeamCodes] = useState<string[]>([]);

    const [create, onCreating] = useMutexCallback(async () => {
        const code = `IA-${getRandomString(4)}-${getRandomString(4)}`;
        await db.collection('sessions').doc(code).set({
            created_at: new Date(),
        });


        const teams = [];
        for (let i = 0; i < 6; i++) {
            const teamCode = `IA${code[code.length - 1]}-${i}${getRandomString(2)}`;
            teams.push(teamCode);

            await db.collection('teams').doc(teamCode).set({
                teamIndex: i,
                teamCode: teamCode,
                sessionId: code,
                created_at: new Date(),
            });
        }



        setSessionId(code);
        setTeamCodes(teams);
    }, []);


    return (
        <div className="min-h-screen w-full relative">
            <div className="absolute inset-0">
                <img src={map} alt="map" className="w-full h-full object-contain"/>

                <div className="absolute inset-0 z-10">
                    <UIScreen>
                        <div className="flex flex-col space-y-2 justify-center items-center">
                            <h1 className="text-2xl font-bold">Insel Arnie</h1>

                            <div>
                                <SimpleButton onClick={create} disabled={onCreating}>
                                    {onCreating ? '...' : 'Neues Spiel erstellen'}
                                </SimpleButton>
                            </div>

                            {!!sessionId && (
                                <>
                                    <h1 className="text-3xl font-bold">
                                        SessionID: <code>{sessionId}</code>
                                    </h1>
                                    <h2 className="text-2xl font-bold">Team Codes</h2>
                                    <ul>
                                        {teamCodes.map(code => (
                                            <li key={code} className="text-2xl">
                                                <code>{code}</code>
                                            </li>
                                        ))}
                                    </ul>

                                    <a href="/uebersicht" className="underline text-blue-200">Zur Übersicht</a>
                                </>
                            )}
                        </div>
                    </UIScreen>
                </div>
            </div>
        </div>
    );
}

export default StartNewSession;

function getRandomString(l: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let s = '';

    for (let i = 0; i < l; i++) {
        s += characters[Math.floor(Math.random() * characters.length)];
    }

    return s;
}